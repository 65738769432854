@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    scroll-behavior: smooth;
  }

  .input_style {
    @apply p-2.5 outline-none rounded-md border hover:border-red-400 shadow-sm;
  }

  .button_style {
    @apply p-2.5 outline-none rounded-md bg-red-500 hover:bg-red-600 shadow-sm;
  }
}
